//import React from 'react'
import { Container } from "react-bootstrap";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { getCurrentDate, getCurrentTime } from "./utils/getCurrentDate";
import { logout } from "./slices/authSlice";

const App = () => {
  // ENABLE THIS CODE FOR AUTO LOGOFF
  // const { userInfo } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // setTimeout(() => {
  //   console.log("currTime - loginTime");
  //   console.log(
  //     new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)
  //   );

  //   if (
  //     userInfo !== null &&
  //     (new Date(getCurrentDate() + getCurrentTime()) -
  //       new Date(userInfo?.loginDate)) /
  //       1000 >
  //       86400
  //   ) {
  //     console.log("clearing redux after 60 minutes");
  //     dispatch(logout());
  //   }
  // }, 60000);

  return (
    <>
      <ToastContainer position="top-center" autoClose="3000" />
      <Header />
      <main className="py-3" style={{ backgroundColor: "#ecf3f2" }}>
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default App;
